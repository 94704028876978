import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/AkiraLogoNewTransparent.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setActiveDropdown(null); // Close all dropdowns when the menu closes
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <header className="bg-slate-100 text-gray-700 py-4 sticky top-0 z-50">
      <div className="container mx-auto px-4 flex items-center justify-between">
        {/* Logo and Title */}
        <div className="flex items-center space-x-6">
          <Link to="/" className="pl-8 md:pl-0 flex items-center space-x-2 text-3xl font-bold">
            <img
              src={Logo}
              alt="Akira Web Solutions Logo"
              className="h-14 rounded-xl hover:grayscale"
            />
          </Link>
          <nav className="hidden md:flex space-x-6 pl-8">
            {/* Company Dropdown (Desktop) */}
            <div className="relative group">
              <button className="hover:text-blue-500 transition-colors duration-200">Company</button>
              <div className="absolute hidden group-hover:flex flex-col bg-white shadow-lg py-2 mt-1 space-y-2 p-4 rounded-lg transition-all duration-300 ease-in-out transform scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100">
              <Link to="/Company" className="text-sm block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">About Us</Link>
              </div>
            </div>

            {/* Learn Dropdown (Desktop) */}
            <div className="relative group">
            <Link to="/Learn" >
              <button className="hover:text-blue-500 transition-colors duration-200">Learn</button>
              </Link>              
              <div className="absolute hidden text-sm group-hover:flex flex-col bg-white shadow-lg py-2 mt-1 space-y-2 p-4 rounded-lg transition-all duration-300 ease-in-out transform scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100">
                
                <Link to="/Learn" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Learning Center</Link>
                <hr/>
<Link to="/Blog" className="block  px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Blog</Link>
<hr/>
                <Link to="/Docs" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Docs</Link>
              </div>
            </div>

            {/* Solutions Dropdown (Desktop) */}
            <div className="relative group">
            <Link to="/Solutions" className=" hover:bg-gray-200">
              <button className="hover:text-blue-500 transition-colors duration-200">Solutions</button>
              </Link>

              <div className="absolute hidden text-sm group-hover:flex flex-col bg-white shadow-lg py-2 mt-1 space-y-2 p-4 rounded-lg transition-all duration-300 ease-in-out transform scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100">
                
                <Link to="/Solutions/ai-agents" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">AI Agents</Link>
                <hr/>

                <Link to="/Solutions/website-builds" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Website Builds</Link>
                <hr/>

                <Link to="/Solutions/database-management" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Database Management</Link>
                <hr/>
                <Link to="/Solutions/seo-strategies" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">SEO</Link>
                <hr/>
                <Link to="/Solutions/cro-strategies" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">CRO</Link>
              </div>
            </div>

            {/* Case Studies Dropdown (Desktop) */}
            <div className="relative group">
            <Link to="/Portfolio">
              <button className="hover:text-blue-500 transition-colors duration-200">Our Work</button>
              </Link>
              <div className="absolute hidden group-hover:flex flex-col bg-white shadow-lg py-2 mt-1 space-y-2 p-4 rounded-lg transition-all duration-300 ease-in-out transform scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100">
              <Link to="/Portfolio" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Case Studies</Link>
              <hr/>
              <Link to="/free-ai-tools" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-lg">Free AI Tools</Link>
              </div>
            </div>
          </nav>
        </div>

        {/* Connect Button */}
        <div className="hidden md:flex items-center space-x-4">
          <Link 
            to="/Contact" 
            className="bg-transparent text-gray-900 border border-gray-300 hover:border-gray-500 px-4 py-2 rounded-md hover:bg-blue-400 hover:text-white transition-colors duration-200"
          >
            Connect
          </Link>
        </div>

        {/* Hamburger menu for mobile */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMobileMenu}
            className="text-gray-600 hover:text-blue-500 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Overlay and Mobile menu */}
      {isMobileMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 z-30"
            onClick={closeMobileMenu}
          ></div>

          {/* Mobile menu */}
          <div className="md:hidden text-center bg-slate-100 text-gray-900 py-4 absolute top-0 left-0 w-full z-40 shadow-lg">
            <button
              className="text-gray-600 hover:text-blue-500 absolute top-4 right-4 focus:outline-none"
              onClick={closeMobileMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <Link
              to="/"
              className="block text-gray-900 hover:text-blue-500 py-2 transition-colors duration-200 border-b border-gray-200"
              onClick={closeMobileMenu}
            >
              Home
            </Link>

            {/* Company Dropdown (Mobile) */}
            <div className='border-b'>
              <button
                className="block w-full text-center text-gray-900 hover:text-blue-500 py-2 transition-colors duration-200  border-gray-200"
                onClick={() => toggleDropdown('company')}
              >
                Company
              </button>
              {activeDropdown === 'company' && (
                <div className="ml-4 space-y-2 transition-all duration-300 ease-in-out">
                  <Link to="/Company" className="block  px-4 py-2 text-center text-gray-500 hover:bg-gray-200">About Us</Link>
                </div>
              )}
            </div>

            {/* Learn Dropdown (Mobile) */}
            <div className='border-b'>
              <button
                className="block w-full text-center text-gray-900 hover:text-blue-500 py-2 transition-colors duration-200 border-gray-200"
                onClick={() => toggleDropdown('learn')}
              >
                Learn
              </button>
              {activeDropdown === 'learn' && (
                <div className="ml-4 space-y-2 text-center transition-all duration-300 ease-in-out">
                  <Link to="/Learn" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Learning Center</Link>
                  <Link to="/Blog" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Blog</Link>
                  <Link to="/Docs" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Docs</Link>
                </div>
              )}
            </div>

            {/* Solutions Dropdown (Mobile) */}
            <div className='border-b'>
              <button
                className="block w-full text-center text-gray-900 hover:text-blue-500 py-2 transition-colors duration-200  border-gray-200"
                onClick={() => toggleDropdown('solutions')}
              >
                Solutions
              </button>
              {activeDropdown === 'solutions' && (
                <div className="ml-4 space-y-2 transition-all duration-300 ease-in-out">
                  {/* <Link to="/Solutions" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Solutions</Link> */}
                  <Link to="/Solutions/ai-agents" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">AI Agents</Link>
                  <Link to="/Solutions/website-builds" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Website Builds</Link>
                  <Link to="/Solutions/database-management" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Database Management</Link>
                  <Link to="/Solutions/seo-strategies" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">SEO</Link>
                  <Link to="/Solutions/cro-strategies" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">CRO</Link>
                </div>
              )}
            </div>

            {/* Case Studies Dropdown (Mobile) */}
            <div className='border-b'>
              <button
                className="block w-full text-center text-gray-900 hover:text-blue-500 py-2 transition-colors duration-200 border-gray-200"
                onClick={() => toggleDropdown('portfolio')}
              >
                Our Work
              </button>
              {activeDropdown === 'portfolio' && (
                <div className="ml-4 space-y-2 transition-all duration-300 ease-in-out">
                  <Link to="/Portfolio" className="block px-4 py-2 text-gray-500 hover:bg-gray-200">Case Studies</Link>
                  <Link to="/Portfolio" className="block px-4 py-2 text-gray-500 hover:bg-gray-200 rounded-lg">Free AI Tools</Link>

                </div>
              )}
            </div>

            <Link
              to="/Contact"
              className="block text-blue-500 border border-blue-500 px-4 py-2 rounded-md mt-4 mx-4 hover:bg-blue-500 hover:text-white transition-colors duration-200"
              onClick={closeMobileMenu}
            >
              Contact
            </Link>
          </div>
        </>
      )}
    </header>
  );
};

export default Navbar;
