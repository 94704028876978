import React from "react";
import backgroundImage from '../assets/AkiraHeader1.png'; // Make sure to replace with appropriate Akira Web Solutions image if needed
import { useLocation } from 'react-router-dom';

const ContactHero = () => {
  const location = useLocation();

 // Function to determine what text to show based on the route
 const getHeadingText = () => {
  switch (location.pathname) {
    case '/':
      return 'Welcome';
    case '/Contact':
      return 'Contact Us';
    case '/Solutions':
      return 'Our Solutions';
      case '/Learn':
        return 'Learning Center';
        case '/Blog':
          return 'Blog';
    case '/Company':
      return 'About Us';
      case '/free-ai-tools':
        return 'AI Tools'
    case '/Portfolio':
      return 'Our Portfolio';
    case '/careers/home':
      return 'Careers';
    case '/docs':
      return 'Documentation';
    case '/Solutions/ai-agents':
      return 'AI Solutions';
    case '/Solutions/website-builds':
      return 'Website Solutions';
    case '/Solutions/seo-strategies':
    case '/Solutions/seo':
      return 'SEO Solutions';
    case '/Solutions/cro-strategies':
      return 'CRO Solutions';
    case '/Solutions/database-management':
      return 'Database Solutions';
    case '/Portfolio/bonds-southeast':
      return 'Portfolio - Bonds Southeast Inc';
    case '/Portfolio/bitcoin-bay':
      return 'Portfolio - Bitcoin Bay';
    case '/Portfolio/seaver-marketing-group':
      return 'Portfolio - Seaver Marketing';
    case '/Portfolio/pmt-site':
      return 'Portfolio - PMT Site';
    case '/Portfolio/saas-shell':
      return 'SaaS Project Portfolio';
    case '/Portfolio/creative-coloring':
      return 'Creative Coloring Portfolio';
    case '/Portfolio/vincent-peach':
      return 'Vincent Peach Portfolio';
    case '/Portfolio/product-listing-optimization':
      return 'Product Listing Optimization Portfolio';
    case '/Portfolio/seo-boost-strategy':
      return 'SEO Boost Strategy Portfolio';
    case '/Portfolio/database-manager':
      return 'Database Manager Portfolio';
    case '/Portfolio/bitcoin-bay-chatbot':
      return 'Bitcoin Bay Chatbot Portfolio';
    case '/Portfolio/3d-model-maker':
      return '3D Model Maker Portfolio';
    case '/docs/ai-agents':
      return 'AI Agents Documentation';
    case '/docs/website-builds':
      return 'Website Builds Documentation';
    case '/docs/database-management':
      return 'Database Management Documentation';
    case '/docs/seo':
      return 'SEO Documentation';
    case '/docs/cro':
      return 'CRO Documentation';
    default:
      return 'Akira Web Solutions';
  }
};

  return (
    <div
      className="relative bg-slate-100 overflow-hidden"
      style={{ height: "45vh" }}
    >
      {/* Background Image */}
      <img
        src={backgroundImage}
        alt="Background"
        className="absolute inset-0 object-cover w-full h-full"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500 via-transparent to-slate-900 opacity-60"></div>
      <div className="absolute inset-0 bg-slate-900 opacity-50"></div>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500 via-transparent to-slate-900 opacity-50"></div>

      {/* Text Container */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full p-4 text-center font-bold">
        <h2 className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold">
          {getHeadingText()}
        </h2>
        <hr className="border-t-2 border-gray-300 w-3/4 my-4" />
        <h2 className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl text-white font-bold">
          Akira Web Solutions
        </h2>
      </div>
    </div>
  );
};

export default ContactHero;
