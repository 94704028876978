import React from 'react';

const CompanyStory = () => {
  return (
    <section className="bg-slate-50">
      <div className="container mx-auto py-12 lg:py-16 px-4">
        {/* Title */}
<h2 className="text-center text-3xl lg:text-4xl font-light text-navy">
Our Story
</h2>

{/* Story Content */}
<div className="mt-8 lg:mt-12 flex flex-col gap-6 text-sm md:text-base text-gray-600 lg:gap-8">
<p>
Akira Web Solutions was founded in 2020 by Vanderbilt University graduate Ryan Hood.
Inspired by the potential of AI and automation to revolutionize businesses, Ryan set out to create a company dedicated to empowering businesses with these technologies.
</p>
<p>
With a team of experienced software developers and online marketing experts, Akira has been helping companies of all sizes achieve their digital goals since its inception.
From building custom websites and managing databases to implementing effective SEO strategies, we're committed to providing innovative solutions that drive growth and success.
</p>
<p>
As technology continues to evolve, Akira Web Solutions remains at the forefront, offering businesses the latest in AI-powered solutions and automation tools.
Our goal is to help you streamline your operations, improve customer engagement, and increase your bottom line.
</p>

        </div>
      </div>
    </section>
  );
};

export default CompanyStory;
