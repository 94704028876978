import React, { useState } from 'react';
import PMTwebsite from '../assets/FreeAIProduct.png';
import SeaverMarketing1 from '../assets/FreeAIContent.png';
import BondsSouthEastSite from '../assets/FreeAI3D.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaMountain } from 'react-icons/fa';

const AIToolsHome = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  // Adjusted responsive settings for the carousel to handle various screen sizes
  const responsive = {
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1023, min: 768 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
  };

  // Our Free AI Tools projects data
  const aiToolsProjects = [
    {
      title: '3D Model Maker',
      description: 'Create 3D assets of real products easily with a video scan!',
      image: BondsSouthEastSite,
      details: 'In collaboration with Luma AI, we created a tool in which you can easily generate 3D assets of real life products. Use 3D models to boost your marketing efforts, enhance your e-commerce store, or speed up game design.',
      link: 'http://app-3dmodel-maker.akirawebsolutions.com/',
    },
    {
      title: 'Content Creator',
      description: 'An Assistant in creating and brainstorming for optimized social media posts.',
      image: SeaverMarketing1,
      details: 'Use this AI Assistant to improve your social media posts, get better results, or even brainstorm for new ideas on what to post about!',
      link: 'http://app-social-content.akirawebsolutions.com/',
    },
    {
      title: 'Product Listing Optimizer',
      description: 'Optimize your E-commerce Listings with this simple-to-use tool.',
      image: PMTwebsite,
      details: 'We crafted a website with SEO and Google Analytics integration, helping the company grow their online presence.',
      link: 'https://app-product-optimizer.akirawebsolutions.com/',
    },
  ];

  return (
    <section className="bg-slate-50 py-12">
      <div className="container mx-auto px-4">
        {/* Section Heading */}
        <div className="flex text-blue-800 items-center text-2xl font-semibold justify-center pb-4">
          <h2 className="flex text-blue-800 items-center text-2xl font-semibold justify-center pb-4">
            <FaMountain className="mr-4 text-blue-800" />
            Our Free AI Tools
            <FaMountain className="ml-4 text-blue-800" />
          </h2>
        </div>

        <div className="text-center mb-8">
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            Explore our free AI tools, including projects that demonstrate our expertise in creating efficient landing pages optimized for user experience and performance.
          </p>
        </div>

        {/* Carousel */}
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          arrows={true}
          itemClass="px-2"
          containerClass="p-2"
          customLeftArrow={
            <button
              className="absolute left-[0px] top-1/2 transform -translate-y-1/2 bg-gray-500 text-white rounded-full p-3 hover:bg-cyan-500 transition-colors duration-300"
              aria-label="Previous"
            >
              ←
            </button>
          }
          customRightArrow={
            <button
              className="absolute right-[0px] top-1/2 transform -translate-y-1/2 bg-gray-500 text-white rounded-full p-3 hover:bg-cyan-500 transition-colors duration-300"
              aria-label="Next"
            >
              →
            </button>
          }
        >
          {aiToolsProjects.map((project, index) => (
            <div
              key={index}
              className="w-[400px] h-[350px] bg-white shadow-lg rounded-lg mx-auto cursor-pointer hover:shadow-xl transition-all duration-300 flex flex-col"
              onClick={() => setSelectedProject(project)}
            >
              <img
                src={project.image}
                alt={project.title}
                className="h-[200px] object-cover w-full rounded-t-lg"
              />
              <div className="p-4 flex-1 flex flex-col justify-between overflow-hidden">
                <h3 className="text-xl font-semibold text-gray-900 mb-2 truncate">{project.title}</h3>
                <p className="text-sm text-gray-600 mb-2 flex-1 overflow-hidden text-ellipsis">
                  {project.description}
                </p>
              </div>
            </div>
          ))}
        </Carousel>

        {/* Modal */}
        {selectedProject && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-lg mx-auto shadow-lg">
              <h2 className="text-2xl font-bold mb-4">{selectedProject.title}</h2>
              <img
                src={selectedProject.image}
                alt={selectedProject.title}
                className="w-full h-48 object-contain mb-4 rounded"
              />
              <p className="text-gray-700 mb-4">{selectedProject.details}</p>
              <p className="text-gray-700 mb-4">
                To use the app,{' '}
                <a
                  href={selectedProject.link}

                  className="text-blue-600 hover:text-blue-500"
                >
                  click here
                </a>.
              </p>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
                onClick={() => setSelectedProject(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AIToolsHome;
